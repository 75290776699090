@import 'default';

$c-font: hsl(0, 0%, 80%);
$c-bg: hsla(0, 0%, 0%, 0.6);
$c-bg-low: hsla(0, 0%, 0%, 0.5);
$c-bg-zebra: hsla(0, 0%, 100%, 0.05);
$c-bg-popup: hsla(0, 0%, 0%, 0.6);

html.transp {
  @include shared-color-defs;

  --c-bg-input: #{$c-bg-low};
  --c-bg-variation: hsla(0, 0%, 0%, 0.25);
  --c-bg-header-dropdown: hsla(0, 0%, 0%, 0.75);

  --c-font-dim: #{change-color($c-font, $lightness: 69%)};
  --c-font-dimmer: #{change-color($c-font, $lightness: 48%)};
  --c-font-clear: #{change-color($c-font, $lightness: 89%)};
  --c-font-clearer: #{change-color($c-font, $lightness: 97%)};
  --c-font-page: #{change-color($c-font, $lightness: 97%)};

  --c-metal-top: hsla(37, 7%, 19%, 0.56);
  --c-metal-bottom: hsla(37, 7%, 19%, 0.66);
  --c-metal-top-hover: hsla(22, 100%, 42%, 0.4);
  --c-metal-bottom-hover: hsla(22, 100%, 42%, 0.3);

  @include transp-mix;
}

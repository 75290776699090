@import 'default';

@function _clear($color, $weight: 17%) {
  @return mix(#000, $color, $weight);
}

@function _dim($color, $weight: 17%) {
  @return mix(#fff, $color, $weight);
}

$c-bg: hsl(0, 0%, 100%); // $c-bg-high
$c-bg-page: hsl(37, 10%, 92%);
$c-bg-low: hsl(0, 0%, 89%);
$c-bg-zebra: hsl(37, 12%, 96.5%);
$c-bg-zebra2: hsl(37, 12%, 92%);
$c-bg-popup: hsl(0, 0%, 100%);
$c-body-gradient: hsl(37, 12%, 84%);
$c-font: hsl(0, 0%, 30%);
$c-primary: hsl(209, 77%, 46%);
$c-brag: hsl(37, 74%, 48%);
$c-shade: hsl(0, 0%, 84%);
$c-inaccuracy: hsl(202, 78%, 40%);
$c-mistake: hsl(41, 100%, 35%);
$c-blunder: hsl(0, 68%, 50%);
$c-good: $c-secondary;
$c-brilliant: hsl(129, 71%, 30%);
$c-interesting: hsl(307, 80%, 59%);
$c-paper: hsl(60, 56%, 86%);
$c-dimmer: #fff;
$c-clearer: #000;

html.light {
  @include shared-color-defs;

  --c-bg-input: #{change-color($c-bg-page, $lightness: 98%)};
  --c-bg-variation: #{$c-bg-zebra2};
  --c-bg-header-dropdown: #{$c-bg};
  --c-header-dropdown: #{$c-font};
  --c-page-input: #{$c-bg-low};
  --c-metal-top: hsl(0, 0%, 96%);
  --c-metal-bottom: hsl(0, 0%, 93%);
  --c-metal-top-hover: hsl(0, 0%, 98%);
  --c-metal-bottom-hover: hsl(0, 0%, 95%);

  --c-font-dim: #{change-color($c-font, $lightness: 47%)};
  --c-font-dimmer: #{change-color($c-font, $lightness: 70%)};
  --c-font-clear: #{change-color($c-font, $lightness: 12%)};
  --c-font-clearer: #{change-color($c-font, $lightness: 0%)};
  --c-font-page: #{change-color($c-font, $lightness: 37%)};
  --c-font-shadow: #fff;

  --c-primary-dim: #{_dim($c-primary)};
  --c-link-hover: #{_clear(saturate($c-primary, 100%), 35%)};
  --c-chat-host-bg: rgba(125, 170, 73, 0.4);
  --c-chat-mention-bg: rgba(161, 194, 124, 0.4);
  --c-fancy: hsl(294, 61%, 62%);

  --c-border: hsl(0, 0%, 85%);
  --c-border-page: hsl(0, 0%, 80%);
  --c-border-tour: #{mix(hsl(0, 0%, 80%), $c-bg-page, 50)};
  --c-border-light: hsl(0, 0%, 80%);

  @include light-mix;
}

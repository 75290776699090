@mixin default-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsl(25, 30%, 17%);
  --m-accent_bg--mix-12: hsl(24, 34%, 17%);
  --m-accent_bg--mix-15: hsl(24, 39%, 18%);
  --m-accent_bg--mix-20: hsl(23, 47%, 20%);
  --m-accent_bg--mix-60: hsl(22, 83%, 31%);
  --m-accent_bg--mix-70: hsl(22, 88%, 34%);
  --m-accent_bg-popup--mix-10: hsl(26, 23%, 24%);
  --m-accent_bg-zebra--mix-10: hsl(25, 24%, 21%);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsl(8, 22%, 18%);
  --m-bad_bg--mix-15: hsl(6, 28%, 19%);
  --m-bad_bg--mix-20: hsl(4, 32%, 21%);
  --m-bad_bg--mix-25: hsl(3, 36%, 23%);
  --m-bad_bg--mix-30: hsl(3, 39%, 25%);
  --m-bad_bg--mix-50: hsl(1, 48%, 32%);
  --m-bad_bg--mix-60: hsl(1, 52%, 36%);
  --m-bad_bg--mix-80: hsl(0, 57%, 43%);
  --m-bad_bg-zebra--mix-20: hsl(4, 27%, 25%);
  --m-bad_bg-zebra2--mix-20: hsl(5, 24%, 29%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 31%, 32%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 47%, 40%);
  --m-bad_clearer--mix-80: hsl(0, 60%, 60%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(37, 7%, 14%, 0.4);
  --m-bg--fade-20: hsla(37, 7%, 14%, 0.8);
  --m-bg--fade-30: hsla(37, 7%, 14%, 0.7);
  --m-bg--fade-40: hsla(37, 7%, 14%, 0.6);
  --m-bg--fade-50: hsla(37, 7%, 14%, 0.5);
  --m-bg-page--fade-40: hsla(37, 10%, 8%, 0.6);
  --m-bg-page_bg--mix-50: hsl(40, 8%, 11%);
  --m-bg-zebra2--fade-1: hsla(37, 5%, 24%, 0.99);
  --m-bg_bad--mix-80: hsl(4, 32%, 21%);
  --m-bg_high--lighten-11: hsl(37, 7%, 25%);
  --m-blunder_bg--mix-25: hsl(3, 30%, 25%);
  --m-blunder_bg--mix-30: hsl(3, 32%, 28%);
  --m-blunder_bg--mix-60: hsl(1, 41%, 42%);
  --m-blunder_font--mix-70: hsl(0, 53%, 64%);
  --m-body-gradient_bg-page--mix-50: hsl(39, 11%, 12%);
  --m-brag--alpha-70: hsla(37, 74%, 43%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 43%, 0.5);
  --m-brag_bg--mix-15: hsl(37, 31%, 18%);
  --m-brag_bg--mix-20: hsl(37, 36%, 20%);
  --m-brag_bg--mix-40: hsl(37, 52%, 26%);
  --m-brag_bg--mix-60: hsl(37, 62%, 31%);
  --m-brag_bg--mix-70: hsl(37, 66%, 34%);
  --m-brag_bg--mix-80: hsl(37, 69%, 37%);
  --m-brag_bg-page--mix-20: hsl(38, 47%, 15%);
  --m-brag_bg-page--mix-60: hsl(37, 67%, 29%);
  --m-brag_dark--mix-85: hsl(37, 68%, 40%);
  --m-brag_font--mix-60: hsl(37, 42%, 55%);
  --m-brag_white--mix-25: hsl(37, 56%, 86%);
  --m-brag_white--mix-80: hsl(37, 56%, 55%);
  --m-brilliant_bg--mix-25: hsl(124, 35%, 22%);
  --m-brilliant_bg--mix-30: hsl(125, 39%, 24%);
  --m-brilliant_bg--mix-60: hsl(128, 58%, 33%);
  --m-clas--alpha-30: hsla(255, 83%, 65%, 0.3);
  --m-clas_bg--mix-10: hsl(267, 13%, 19%);
  --m-clas_bg--mix-30: hsl(257, 29%, 29%);
  --m-clas_bg--mix-7: hsl(275, 9%, 17%);
  --m-clas_bg--mix-80: hsl(255, 51%, 54%);
  --m-clas_bg-zebra--mix-20: hsl(260, 19%, 28%);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-clas_white--mix-28: hsl(255, 83%, 90%);
  --m-clearer_bg-page--mix-70: hsl(45, 1%, 72%);
  --m-clearer_bg-page--mix-80: hsl(45, 1%, 82%);
  --m-clearer_body-gradient--mix-65: hsl(36, 2%, 71%);
  --m-clearer_body-gradient--mix-75: hsl(36, 2%, 79%);
  --m-dark--fade-40: hsla(0, 0%, 20%, 0.6);
  --m-dimmer_clearer--mix-63: hsl(0, 0%, 37%);
  --m-dimmer_font--mix-60: hsl(0, 0%, 29%);
  --m-dimmer_font--mix-90: hsl(0, 0%, 7%);
  --m-font--fade-50: hsla(0, 0%, 73%, 0.5);
  --m-font--fade-70: hsla(0, 0%, 73%, 0.3);
  --m-font--fade-83: hsla(0, 0%, 73%, 0.17);
  --m-font_bg--mix-20: hsl(36, 3%, 26%);
  --m-font_bg--mix-30: hsl(36, 2%, 32%);
  --m-font_clas--mix-40: hsl(255, 55%, 68%);
  --m-font_dimmer--mix-50: hsl(0, 0%, 36%);
  --m-font_dimmer--mix-85: hsl(0, 0%, 62%);
  --m-font_white--mix-20: hsl(0, 0%, 95%);
  --m-good_bg--mix-25: hsl(83, 32%, 19%);
  --m-good_bg--mix-30: hsl(84, 35%, 21%);
  --m-good_bg--mix-60: hsl(87, 51%, 28%);
  --m-inaccuracy_bg--mix-25: hsl(201, 26%, 26%);
  --m-inaccuracy_bg--mix-30: hsl(201, 29%, 28%);
  --m-inaccuracy_bg--mix-60: hsl(202, 41%, 43%);
  --m-inaccuracy_font--mix-70: hsl(202, 60%, 65%);
  --m-interesting_bg--mix-25: hsl(314, 22%, 28%);
  --m-interesting_bg--mix-30: hsl(313, 24%, 31%);
  --m-interesting_bg--mix-60: hsl(309, 31%, 48%);
  --m-mistake_bg--mix-25: hsl(41, 55%, 22%);
  --m-mistake_bg--mix-30: hsl(41, 61%, 23%);
  --m-mistake_bg--mix-60: hsl(41, 84%, 33%);
  --m-mistake_font--mix-70: hsl(41, 68%, 53%);
  --m-paper--fade-40: hsla(60, 56%, 91%, 0.6);
  --m-paper_dimmer--mix-50: hsl(60, 6%, 45%);
  --m-paper_dimmer--mix-75: hsl(60, 12%, 68%);
  --m-primary--alpha-30: hsla(209, 79%, 56%, 0.3);
  --m-primary--alpha-70: hsla(209, 79%, 56%, 0.7);
  --m-primary--fade-50: hsla(209, 79%, 56%, 0.5);
  --m-primary--fade-70: hsla(209, 79%, 56%, 0.3);
  --m-primary--fade-80: hsla(209, 79%, 56%, 0.2);
  --m-primary--lighten-10: hsl(209, 79%, 66%);
  --m-primary--lighten-11: hsl(209, 79%, 67%);
  --m-primary--lighten-7: hsl(209, 79%, 63%);
  --m-primary_bg--mix-10: hsl(206, 14%, 18%);
  --m-primary_bg--mix-12: hsl(207, 17%, 19%);
  --m-primary_bg--mix-15: hsl(207, 22%, 20%);
  --m-primary_bg--mix-17: hsl(208, 24%, 21%);
  --m-primary_bg--mix-18: hsl(208, 25%, 21%);
  --m-primary_bg--mix-20: hsl(208, 28%, 22%);
  --m-primary_bg--mix-25: hsl(208, 33%, 24%);
  --m-primary_bg--mix-30: hsl(208, 37%, 27%);
  --m-primary_bg--mix-35: hsl(208, 40%, 29%);
  --m-primary_bg--mix-40: hsl(208, 43%, 31%);
  --m-primary_bg--mix-50: hsl(209, 48%, 35%);
  --m-primary_bg--mix-60: hsl(209, 52%, 39%);
  --m-primary_bg--mix-70: hsl(209, 55%, 43%);
  --m-primary_bg--mix-75: hsl(209, 57%, 45%);
  --m-primary_bg--mix-80: hsl(209, 58%, 47%);
  --m-primary_bg-page--mix-15: hsl(206, 30%, 15%);
  --m-primary_bg-page--mix-20: hsl(207, 36%, 17%);
  --m-primary_bg-page--mix-60: hsl(209, 56%, 37%);
  --m-primary_bg-page__mix-20--fade-70: hsla(209, 79%, 56%, 0.3);
  --m-primary_bg-zebra--mix-10: hsl(206, 11%, 23%);
  --m-primary_bg-zebra2--mix-20: hsl(207, 20%, 30%);
  --m-primary_black--mix-70: hsl(209, 62%, 39%);
  --m-primary_black--mix-80: hsl(209, 62%, 45%);
  --m-primary_dark--mix-80: hsl(209, 57%, 49%);
  --m-primary_font--mix-35: hsl(209, 37%, 67%);
  --m-primary_font--mix-50: hsl(209, 49%, 64%);
  --m-primary_font--mix-90: hsl(209, 74%, 58%);
  --m-primary_white--mix-30: hsl(209, 79%, 87%);
  --m-primary_white--mix-40: hsl(209, 79%, 82%);
  --m-primary_white--mix-60: hsl(209, 79%, 74%);
  --m-primary_white--mix-80: hsl(209, 79%, 65%);
  --m-secondary--fade-30: hsla(88, 62%, 37%, 0.7);
  --m-secondary--fade-40: hsla(88, 62%, 37%, 0.6);
  --m-secondary--fade-50: hsla(88, 62%, 37%, 0.5);
  --m-secondary--fade-60: hsla(88, 62%, 37%, 0.4);
  --m-secondary--fade-80: hsla(88, 62%, 37%, 0.2);
  --m-secondary--lighten-11: hsl(88, 62%, 48%);
  --m-secondary--lighten-4: hsl(88, 62%, 41%);
  --m-secondary_bg--mix-10: hsl(75, 18%, 16%);
  --m-secondary_bg--mix-12: hsl(77, 20%, 16%);
  --m-secondary_bg--mix-15: hsl(80, 23%, 17%);
  --m-secondary_bg--mix-20: hsl(82, 28%, 18%);
  --m-secondary_bg--mix-25: hsl(83, 32%, 19%);
  --m-secondary_bg--mix-30: hsl(84, 35%, 21%);
  --m-secondary_bg--mix-35: hsl(85, 39%, 22%);
  --m-secondary_bg--mix-40: hsl(86, 42%, 23%);
  --m-secondary_bg--mix-50: hsl(87, 47%, 25%);
  --m-secondary_bg--mix-70: hsl(87, 54%, 30%);
  --m-secondary_bg--mix-75: hsl(88, 56%, 31%);
  --m-secondary_bg--mix-80: hsl(88, 57%, 32%);
  --m-secondary_bg-page--mix-50: hsl(87, 53%, 22%);
  --m-secondary_bg-popup--mix-10: hsl(70, 14%, 23%);
  --m-secondary_bg-popup--mix-27: hsl(82, 27%, 26%);
  --m-secondary_bg-popup--mix-36: hsl(84, 33%, 27%);
  --m-secondary_bg-zebra--mix-12: hsl(77, 16%, 21%);
  --m-secondary_bg-zebra--mix-20: hsl(82, 23%, 22%);
  --m-secondary_dark--mix-50: hsl(88, 40%, 29%);
  --m-secondary_dark--mix-85: hsl(88, 57%, 35%);
  --m-secondary_dimmer--mix-82: hsl(88, 62%, 30%);
  --m-secondary_white--mix-80: hsl(88, 37%, 50%);
  --m-shade--fade-40: hsla(0, 0%, 30%, 0.6);
  --m-yellow_bg--mix-30: hsl(59, 63%, 25%);
}

@mixin font-face-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsl(25, 30%, 17%);
  --m-accent_bg--mix-12: hsl(24, 34%, 17%);
  --m-accent_bg--mix-15: hsl(24, 39%, 18%);
  --m-accent_bg--mix-20: hsl(23, 47%, 20%);
  --m-accent_bg--mix-60: hsl(22, 83%, 31%);
  --m-accent_bg--mix-70: hsl(22, 88%, 34%);
  --m-accent_bg-popup--mix-10: hsl(26, 23%, 24%);
  --m-accent_bg-zebra--mix-10: hsl(25, 24%, 21%);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsl(8, 22%, 18%);
  --m-bad_bg--mix-15: hsl(6, 28%, 19%);
  --m-bad_bg--mix-20: hsl(4, 32%, 21%);
  --m-bad_bg--mix-25: hsl(3, 36%, 23%);
  --m-bad_bg--mix-30: hsl(3, 39%, 25%);
  --m-bad_bg--mix-50: hsl(1, 48%, 32%);
  --m-bad_bg--mix-60: hsl(1, 52%, 36%);
  --m-bad_bg--mix-80: hsl(0, 57%, 43%);
  --m-bad_bg-zebra--mix-20: hsl(4, 27%, 25%);
  --m-bad_bg-zebra2--mix-20: hsl(5, 24%, 29%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 31%, 32%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 47%, 40%);
  --m-bad_clearer--mix-80: hsl(0, 60%, 60%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(37, 7%, 14%, 0.4);
  --m-bg--fade-20: hsla(37, 7%, 14%, 0.8);
  --m-bg--fade-30: hsla(37, 7%, 14%, 0.7);
  --m-bg--fade-40: hsla(37, 7%, 14%, 0.6);
  --m-bg--fade-50: hsla(37, 7%, 14%, 0.5);
  --m-bg-page--fade-40: hsla(37, 10%, 8%, 0.6);
  --m-bg-page_bg--mix-50: hsl(40, 8%, 11%);
  --m-bg-zebra2--fade-1: hsla(37, 5%, 24%, 0.99);
  --m-bg_bad--mix-80: hsl(4, 32%, 21%);
  --m-bg_high--lighten-11: hsl(37, 7%, 25%);
  --m-blunder_bg--mix-25: hsl(3, 30%, 25%);
  --m-blunder_bg--mix-30: hsl(3, 32%, 28%);
  --m-blunder_bg--mix-60: hsl(1, 41%, 42%);
  --m-blunder_font--mix-70: hsl(0, 53%, 64%);
  --m-body-gradient_bg-page--mix-50: hsl(39, 11%, 12%);
  --m-brag--alpha-70: hsla(37, 74%, 43%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 43%, 0.5);
  --m-brag_bg--mix-15: hsl(37, 31%, 18%);
  --m-brag_bg--mix-20: hsl(37, 36%, 20%);
  --m-brag_bg--mix-40: hsl(37, 52%, 26%);
  --m-brag_bg--mix-60: hsl(37, 62%, 31%);
  --m-brag_bg--mix-70: hsl(37, 66%, 34%);
  --m-brag_bg--mix-80: hsl(37, 69%, 37%);
  --m-brag_bg-page--mix-20: hsl(38, 47%, 15%);
  --m-brag_bg-page--mix-60: hsl(37, 67%, 29%);
  --m-brag_dark--mix-85: hsl(37, 68%, 40%);
  --m-brag_font--mix-60: hsl(37, 42%, 55%);
  --m-brag_white--mix-25: hsl(37, 56%, 86%);
  --m-brag_white--mix-80: hsl(37, 56%, 55%);
  --m-brilliant_bg--mix-25: hsl(124, 35%, 22%);
  --m-brilliant_bg--mix-30: hsl(125, 39%, 24%);
  --m-brilliant_bg--mix-60: hsl(128, 58%, 33%);
  --m-clas--alpha-30: hsla(255, 83%, 65%, 0.3);
  --m-clas_bg--mix-10: hsl(267, 13%, 19%);
  --m-clas_bg--mix-30: hsl(257, 29%, 29%);
  --m-clas_bg--mix-7: hsl(275, 9%, 17%);
  --m-clas_bg--mix-80: hsl(255, 51%, 54%);
  --m-clas_bg-zebra--mix-20: hsl(260, 19%, 28%);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-clas_white--mix-28: hsl(255, 83%, 90%);
  --m-clearer_bg-page--mix-70: hsl(45, 1%, 72%);
  --m-clearer_bg-page--mix-80: hsl(45, 1%, 82%);
  --m-clearer_body-gradient--mix-65: hsl(36, 2%, 71%);
  --m-clearer_body-gradient--mix-75: hsl(36, 2%, 79%);
  --m-dark--fade-40: hsla(0, 0%, 20%, 0.6);
  --m-dimmer_clearer--mix-63: hsl(0, 0%, 37%);
  --m-dimmer_font--mix-60: hsl(0, 0%, 29%);
  --m-dimmer_font--mix-90: hsl(0, 0%, 7%);
  --m-font--fade-50: hsla(0, 0%, 73%, 0.5);
  --m-font--fade-70: hsla(0, 0%, 73%, 0.3);
  --m-font--fade-83: hsla(0, 0%, 73%, 0.17);
  --m-font_bg--mix-20: hsl(36, 3%, 26%);
  --m-font_bg--mix-30: hsl(36, 2%, 32%);
  --m-font_clas--mix-40: hsl(255, 55%, 68%);
  --m-font_dimmer--mix-50: hsl(0, 0%, 36%);
  --m-font_dimmer--mix-85: hsl(0, 0%, 62%);
  --m-font_white--mix-20: hsl(0, 0%, 95%);
  --m-good_bg--mix-25: hsl(83, 32%, 19%);
  --m-good_bg--mix-30: hsl(84, 35%, 21%);
  --m-good_bg--mix-60: hsl(87, 51%, 28%);
  --m-inaccuracy_bg--mix-25: hsl(201, 26%, 26%);
  --m-inaccuracy_bg--mix-30: hsl(201, 29%, 28%);
  --m-inaccuracy_bg--mix-60: hsl(202, 41%, 43%);
  --m-inaccuracy_font--mix-70: hsl(202, 60%, 65%);
  --m-interesting_bg--mix-25: hsl(314, 22%, 28%);
  --m-interesting_bg--mix-30: hsl(313, 24%, 31%);
  --m-interesting_bg--mix-60: hsl(309, 31%, 48%);
  --m-mistake_bg--mix-25: hsl(41, 55%, 22%);
  --m-mistake_bg--mix-30: hsl(41, 61%, 23%);
  --m-mistake_bg--mix-60: hsl(41, 84%, 33%);
  --m-mistake_font--mix-70: hsl(41, 68%, 53%);
  --m-paper--fade-40: hsla(60, 56%, 91%, 0.6);
  --m-paper_dimmer--mix-50: hsl(60, 6%, 45%);
  --m-paper_dimmer--mix-75: hsl(60, 12%, 68%);
  --m-primary--alpha-30: hsla(209, 79%, 56%, 0.3);
  --m-primary--alpha-70: hsla(209, 79%, 56%, 0.7);
  --m-primary--fade-50: hsla(209, 79%, 56%, 0.5);
  --m-primary--fade-70: hsla(209, 79%, 56%, 0.3);
  --m-primary--fade-80: hsla(209, 79%, 56%, 0.2);
  --m-primary--lighten-10: hsl(209, 79%, 66%);
  --m-primary--lighten-11: hsl(209, 79%, 67%);
  --m-primary--lighten-7: hsl(209, 79%, 63%);
  --m-primary_bg--mix-10: hsl(206, 14%, 18%);
  --m-primary_bg--mix-12: hsl(207, 17%, 19%);
  --m-primary_bg--mix-15: hsl(207, 22%, 20%);
  --m-primary_bg--mix-17: hsl(208, 24%, 21%);
  --m-primary_bg--mix-18: hsl(208, 25%, 21%);
  --m-primary_bg--mix-20: hsl(208, 28%, 22%);
  --m-primary_bg--mix-25: hsl(208, 33%, 24%);
  --m-primary_bg--mix-30: hsl(208, 37%, 27%);
  --m-primary_bg--mix-35: hsl(208, 40%, 29%);
  --m-primary_bg--mix-40: hsl(208, 43%, 31%);
  --m-primary_bg--mix-50: hsl(209, 48%, 35%);
  --m-primary_bg--mix-60: hsl(209, 52%, 39%);
  --m-primary_bg--mix-70: hsl(209, 55%, 43%);
  --m-primary_bg--mix-75: hsl(209, 57%, 45%);
  --m-primary_bg--mix-80: hsl(209, 58%, 47%);
  --m-primary_bg-page--mix-15: hsl(206, 30%, 15%);
  --m-primary_bg-page--mix-20: hsl(207, 36%, 17%);
  --m-primary_bg-page--mix-60: hsl(209, 56%, 37%);
  --m-primary_bg-page__mix-20--fade-70: hsla(209, 79%, 56%, 0.3);
  --m-primary_bg-zebra--mix-10: hsl(206, 11%, 23%);
  --m-primary_bg-zebra2--mix-20: hsl(207, 20%, 30%);
  --m-primary_black--mix-70: hsl(209, 62%, 39%);
  --m-primary_black--mix-80: hsl(209, 62%, 45%);
  --m-primary_dark--mix-80: hsl(209, 57%, 49%);
  --m-primary_font--mix-35: hsl(209, 37%, 67%);
  --m-primary_font--mix-50: hsl(209, 49%, 64%);
  --m-primary_font--mix-90: hsl(209, 74%, 58%);
  --m-primary_white--mix-30: hsl(209, 79%, 87%);
  --m-primary_white--mix-40: hsl(209, 79%, 82%);
  --m-primary_white--mix-60: hsl(209, 79%, 74%);
  --m-primary_white--mix-80: hsl(209, 79%, 65%);
  --m-secondary--fade-30: hsla(88, 62%, 37%, 0.7);
  --m-secondary--fade-40: hsla(88, 62%, 37%, 0.6);
  --m-secondary--fade-50: hsla(88, 62%, 37%, 0.5);
  --m-secondary--fade-60: hsla(88, 62%, 37%, 0.4);
  --m-secondary--fade-80: hsla(88, 62%, 37%, 0.2);
  --m-secondary--lighten-11: hsl(88, 62%, 48%);
  --m-secondary--lighten-4: hsl(88, 62%, 41%);
  --m-secondary_bg--mix-10: hsl(75, 18%, 16%);
  --m-secondary_bg--mix-12: hsl(77, 20%, 16%);
  --m-secondary_bg--mix-15: hsl(80, 23%, 17%);
  --m-secondary_bg--mix-20: hsl(82, 28%, 18%);
  --m-secondary_bg--mix-25: hsl(83, 32%, 19%);
  --m-secondary_bg--mix-30: hsl(84, 35%, 21%);
  --m-secondary_bg--mix-35: hsl(85, 39%, 22%);
  --m-secondary_bg--mix-40: hsl(86, 42%, 23%);
  --m-secondary_bg--mix-50: hsl(87, 47%, 25%);
  --m-secondary_bg--mix-70: hsl(87, 54%, 30%);
  --m-secondary_bg--mix-75: hsl(88, 56%, 31%);
  --m-secondary_bg--mix-80: hsl(88, 57%, 32%);
  --m-secondary_bg-page--mix-50: hsl(87, 53%, 22%);
  --m-secondary_bg-popup--mix-10: hsl(70, 14%, 23%);
  --m-secondary_bg-popup--mix-27: hsl(82, 27%, 26%);
  --m-secondary_bg-popup--mix-36: hsl(84, 33%, 27%);
  --m-secondary_bg-zebra--mix-12: hsl(77, 16%, 21%);
  --m-secondary_bg-zebra--mix-20: hsl(82, 23%, 22%);
  --m-secondary_dark--mix-50: hsl(88, 40%, 29%);
  --m-secondary_dark--mix-85: hsl(88, 57%, 35%);
  --m-secondary_dimmer--mix-82: hsl(88, 62%, 30%);
  --m-secondary_white--mix-80: hsl(88, 37%, 50%);
  --m-shade--fade-40: hsla(0, 0%, 30%, 0.6);
  --m-yellow_bg--mix-30: hsl(59, 63%, 25%);
}

@mixin light-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsl(22, 72%, 94%);
  --m-accent_bg--mix-12: hsl(22, 72%, 93%);
  --m-accent_bg--mix-15: hsl(22, 72%, 91%);
  --m-accent_bg--mix-20: hsl(22, 72%, 88%);
  --m-accent_bg--mix-60: hsl(22, 72%, 65%);
  --m-accent_bg--mix-70: hsl(22, 72%, 59%);
  --m-accent_bg-popup--mix-10: hsl(22, 72%, 94%);
  --m-accent_bg-zebra--mix-10: hsl(23, 51%, 91%);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsl(0, 60%, 95%);
  --m-bad_bg--mix-15: hsl(0, 60%, 93%);
  --m-bad_bg--mix-20: hsl(0, 60%, 90%);
  --m-bad_bg--mix-25: hsl(0, 60%, 88%);
  --m-bad_bg--mix-30: hsl(0, 60%, 85%);
  --m-bad_bg--mix-50: hsl(0, 60%, 75%);
  --m-bad_bg--mix-60: hsl(0, 60%, 70%);
  --m-bad_bg--mix-80: hsl(0, 60%, 60%);
  --m-bad_bg-zebra--mix-20: hsl(1, 49%, 87%);
  --m-bad_bg-zebra2--mix-20: hsl(4, 41%, 84%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 47%, 79%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 55%, 67%);
  --m-bad_clearer--mix-80: hsl(0, 60%, 40%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(0, 0%, 100%, 0.4);
  --m-bg--fade-20: hsla(0, 0%, 100%, 0.8);
  --m-bg--fade-30: hsla(0, 0%, 100%, 0.7);
  --m-bg--fade-40: hsla(0, 0%, 100%, 0.6);
  --m-bg--fade-50: hsla(0, 0%, 100%, 0.5);
  --m-bg-page--fade-40: hsla(37, 10%, 92%, 0.6);
  --m-bg-page_bg--mix-50: hsl(30, 10%, 96%);
  --m-bg-zebra2--fade-1: hsla(37, 12%, 92%, 0.99);
  --m-bg_bad--mix-80: hsl(0, 60%, 90%);
  --m-bg_high--lighten-11: hsl(0, 0%, 100%);
  --m-blunder_bg--mix-25: hsl(0, 68%, 88%);
  --m-blunder_bg--mix-30: hsl(0, 68%, 85%);
  --m-blunder_bg--mix-60: hsl(0, 68%, 70%);
  --m-blunder_font--mix-70: hsl(0, 54%, 44%);
  --m-body-gradient_bg-page--mix-50: hsl(34, 11%, 88%);
  --m-brag--alpha-70: hsla(37, 74%, 48%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 48%, 0.5);
  --m-brag_bg--mix-15: hsl(37, 68%, 92%);
  --m-brag_bg--mix-20: hsl(37, 68%, 90%);
  --m-brag_bg--mix-40: hsl(37, 68%, 79%);
  --m-brag_bg--mix-60: hsl(37, 68%, 69%);
  --m-brag_bg--mix-70: hsl(37, 68%, 64%);
  --m-brag_bg--mix-80: hsl(37, 68%, 58%);
  --m-brag_bg-page--mix-20: hsl(37, 46%, 83%);
  --m-brag_bg-page--mix-60: hsl(37, 63%, 66%);
  --m-brag_dark--mix-85: hsl(37, 69%, 44%);
  --m-brag_font--mix-60: hsl(37, 52%, 41%);
  --m-brag_white--mix-25: hsl(37, 68%, 87%);
  --m-brag_white--mix-80: hsl(37, 68%, 58%);
  --m-brilliant_bg--mix-25: hsl(129, 31%, 83%);
  --m-brilliant_bg--mix-30: hsl(129, 31%, 79%);
  --m-brilliant_bg--mix-60: hsl(129, 31%, 58%);
  --m-clas--alpha-30: hsla(255, 83%, 65%, 0.3);
  --m-clas_bg--mix-10: hsl(255, 83%, 96%);
  --m-clas_bg--mix-30: hsl(255, 83%, 89%);
  --m-clas_bg--mix-7: hsl(255, 83%, 98%);
  --m-clas_bg--mix-80: hsl(255, 83%, 72%);
  --m-clas_bg-zebra--mix-20: hsl(257, 57%, 90%);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-clas_white--mix-28: hsl(255, 83%, 90%);
  --m-clearer_bg-page--mix-70: hsl(30, 1%, 28%);
  --m-clearer_bg-page--mix-80: hsl(30, 1%, 18%);
  --m-clearer_body-gradient--mix-65: hsl(36, 2%, 29%);
  --m-clearer_body-gradient--mix-75: hsl(36, 2%, 21%);
  --m-dark--fade-40: hsla(0, 0%, 20%, 0.6);
  --m-dimmer_clearer--mix-63: hsl(0, 0%, 63%);
  --m-dimmer_font--mix-60: hsl(0, 0%, 72%);
  --m-dimmer_font--mix-90: hsl(0, 0%, 93%);
  --m-font--fade-50: hsla(0, 0%, 30%, 0.5);
  --m-font--fade-70: hsla(0, 0%, 30%, 0.3);
  --m-font--fade-83: hsla(0, 0%, 30%, 0.17);
  --m-font_bg--mix-20: hsl(0, 0%, 86%);
  --m-font_bg--mix-30: hsl(0, 0%, 79%);
  --m-font_clas--mix-40: hsl(255, 36%, 51%);
  --m-font_dimmer--mix-50: hsl(0, 0%, 65%);
  --m-font_dimmer--mix-85: hsl(0, 0%, 41%);
  --m-font_white--mix-20: hsl(0, 0%, 86%);
  --m-good_bg--mix-25: hsl(88, 36%, 84%);
  --m-good_bg--mix-30: hsl(88, 36%, 81%);
  --m-good_bg--mix-60: hsl(88, 36%, 62%);
  --m-inaccuracy_bg--mix-25: hsl(202, 52%, 85%);
  --m-inaccuracy_bg--mix-30: hsl(202, 52%, 82%);
  --m-inaccuracy_bg--mix-60: hsl(202, 52%, 64%);
  --m-inaccuracy_font--mix-70: hsl(202, 59%, 37%);
  --m-interesting_bg--mix-25: hsl(307, 80%, 90%);
  --m-interesting_bg--mix-30: hsl(307, 80%, 88%);
  --m-interesting_bg--mix-60: hsl(307, 80%, 75%);
  --m-mistake_bg--mix-25: hsl(41, 54%, 84%);
  --m-mistake_bg--mix-30: hsl(41, 54%, 81%);
  --m-mistake_bg--mix-60: hsl(41, 54%, 61%);
  --m-mistake_font--mix-70: hsl(41, 73%, 34%);
  --m-paper--fade-40: hsla(60, 56%, 86%, 0.6);
  --m-paper_dimmer--mix-50: hsl(60, 56%, 93%);
  --m-paper_dimmer--mix-75: hsl(60, 56%, 89%);
  --m-primary--alpha-30: hsla(209, 77%, 46%, 0.3);
  --m-primary--alpha-70: hsla(209, 77%, 46%, 0.7);
  --m-primary--fade-50: hsla(209, 77%, 46%, 0.5);
  --m-primary--fade-70: hsla(209, 77%, 46%, 0.3);
  --m-primary--fade-80: hsla(209, 77%, 46%, 0.2);
  --m-primary--lighten-10: hsl(209, 77%, 56%);
  --m-primary--lighten-11: hsl(209, 77%, 57%);
  --m-primary--lighten-7: hsl(209, 77%, 53%);
  --m-primary_bg--mix-10: hsl(209, 66%, 95%);
  --m-primary_bg--mix-12: hsl(209, 66%, 94%);
  --m-primary_bg--mix-15: hsl(209, 66%, 92%);
  --m-primary_bg--mix-17: hsl(209, 66%, 91%);
  --m-primary_bg--mix-18: hsl(209, 66%, 90%);
  --m-primary_bg--mix-20: hsl(209, 66%, 89%);
  --m-primary_bg--mix-25: hsl(209, 66%, 87%);
  --m-primary_bg--mix-30: hsl(209, 66%, 84%);
  --m-primary_bg--mix-35: hsl(209, 66%, 81%);
  --m-primary_bg--mix-40: hsl(209, 66%, 78%);
  --m-primary_bg--mix-50: hsl(209, 66%, 73%);
  --m-primary_bg--mix-60: hsl(209, 66%, 68%);
  --m-primary_bg--mix-70: hsl(209, 66%, 62%);
  --m-primary_bg--mix-75: hsl(209, 66%, 60%);
  --m-primary_bg--mix-80: hsl(209, 66%, 57%);
  --m-primary_bg-page--mix-15: hsl(209, 32%, 85%);
  --m-primary_bg-page--mix-20: hsl(209, 38%, 83%);
  --m-primary_bg-page--mix-60: hsl(209, 59%, 65%);
  --m-primary_bg-page__mix-20--fade-70: hsla(209, 77%, 46%, 0.3);
  --m-primary_bg-zebra--mix-10: hsl(209, 37%, 91%);
  --m-primary_bg-zebra2--mix-20: hsl(208, 37%, 83%);
  --m-primary_black--mix-70: hsl(209, 77%, 32%);
  --m-primary_black--mix-80: hsl(209, 77%, 37%);
  --m-primary_dark--mix-80: hsl(209, 69%, 41%);
  --m-primary_font--mix-35: hsl(209, 35%, 36%);
  --m-primary_font--mix-50: hsl(209, 47%, 38%);
  --m-primary_font--mix-90: hsl(209, 72%, 44%);
  --m-primary_white--mix-30: hsl(209, 66%, 84%);
  --m-primary_white--mix-40: hsl(209, 66%, 78%);
  --m-primary_white--mix-60: hsl(209, 66%, 68%);
  --m-primary_white--mix-80: hsl(209, 66%, 57%);
  --m-secondary--fade-30: hsla(88, 62%, 37%, 0.7);
  --m-secondary--fade-40: hsla(88, 62%, 37%, 0.6);
  --m-secondary--fade-50: hsla(88, 62%, 37%, 0.5);
  --m-secondary--fade-60: hsla(88, 62%, 37%, 0.4);
  --m-secondary--fade-80: hsla(88, 62%, 37%, 0.2);
  --m-secondary--lighten-11: hsl(88, 62%, 48%);
  --m-secondary--lighten-4: hsl(88, 62%, 41%);
  --m-secondary_bg--mix-10: hsl(88, 36%, 94%);
  --m-secondary_bg--mix-12: hsl(88, 36%, 92%);
  --m-secondary_bg--mix-15: hsl(88, 36%, 91%);
  --m-secondary_bg--mix-20: hsl(88, 36%, 87%);
  --m-secondary_bg--mix-25: hsl(88, 36%, 84%);
  --m-secondary_bg--mix-30: hsl(88, 36%, 81%);
  --m-secondary_bg--mix-35: hsl(88, 36%, 78%);
  --m-secondary_bg--mix-40: hsl(88, 36%, 75%);
  --m-secondary_bg--mix-50: hsl(88, 36%, 69%);
  --m-secondary_bg--mix-70: hsl(88, 36%, 56%);
  --m-secondary_bg--mix-75: hsl(88, 36%, 53%);
  --m-secondary_bg--mix-80: hsl(88, 37%, 50%);
  --m-secondary_bg-page--mix-50: hsl(87, 33%, 64%);
  --m-secondary_bg-popup--mix-10: hsl(88, 36%, 94%);
  --m-secondary_bg-popup--mix-27: hsl(88, 36%, 83%);
  --m-secondary_bg-popup--mix-36: hsl(88, 36%, 77%);
  --m-secondary_bg-zebra--mix-12: hsl(83, 27%, 89%);
  --m-secondary_bg-zebra--mix-20: hsl(85, 30%, 84%);
  --m-secondary_dark--mix-50: hsl(88, 40%, 29%);
  --m-secondary_dark--mix-85: hsl(88, 57%, 35%);
  --m-secondary_dimmer--mix-82: hsl(88, 39%, 48%);
  --m-secondary_white--mix-80: hsl(88, 37%, 50%);
  --m-shade--fade-40: hsla(0, 0%, 84%, 0.6);
  --m-yellow_bg--mix-30: hsl(60, 100%, 85%);
}

@mixin transp-mix {
  --m-accent--fade-30: hsla(22, 100%, 42%, 0.7);
  --m-accent--fade-40: hsla(22, 100%, 42%, 0.6);
  --m-accent--fade-50: hsla(22, 100%, 42%, 0.5);
  --m-accent--fade-70: hsla(22, 100%, 42%, 0.3);
  --m-accent--fade-80: hsla(22, 100%, 42%, 0.2);
  --m-accent--fade-85: hsla(22, 100%, 42%, 0.15);
  --m-accent_bg--mix-10: hsla(22, 100%, 4%, 0.64);
  --m-accent_bg--mix-12: hsla(22, 100%, 5%, 0.65);
  --m-accent_bg--mix-15: hsla(22, 100%, 6%, 0.66);
  --m-accent_bg--mix-20: hsla(22, 100%, 8%, 0.68);
  --m-accent_bg--mix-60: hsla(22, 100%, 25%, 0.84);
  --m-accent_bg--mix-70: hsla(22, 100%, 29%, 0.88);
  --m-accent_bg-popup--mix-10: hsla(22, 100%, 4%, 0.64);
  --m-accent_bg-zebra--mix-10: hsla(22, 72%, 94%, 0.15);
  --m-accent_black--mix-70: hsl(22, 100%, 29%);
  --m-accent_white--mix-80: hsl(22, 72%, 54%);
  --m-bad--alpha-50: hsla(0, 60%, 50%, 0.5);
  --m-bad--fade-40: hsla(0, 60%, 50%, 0.6);
  --m-bad--fade-80: hsla(0, 60%, 50%, 0.2);
  --m-bad--lighten-11: hsl(0, 60%, 61%);
  --m-bad_bg--mix-10: hsla(0, 60%, 5%, 0.64);
  --m-bad_bg--mix-15: hsla(0, 60%, 8%, 0.66);
  --m-bad_bg--mix-20: hsla(0, 60%, 10%, 0.68);
  --m-bad_bg--mix-25: hsla(0, 60%, 13%, 0.7);
  --m-bad_bg--mix-30: hsla(0, 60%, 15%, 0.72);
  --m-bad_bg--mix-50: hsla(0, 60%, 25%, 0.8);
  --m-bad_bg--mix-60: hsla(0, 60%, 30%, 0.84);
  --m-bad_bg--mix-80: hsla(0, 60%, 40%, 0.92);
  --m-bad_bg-zebra--mix-20: hsla(0, 60%, 90%, 0.24);
  --m-bad_bg-zebra2--mix-20: hsl(5, 24%, 29%);
  --m-bad_bg-zebra2--mix-30: hsl(3, 31%, 32%);
  --m-bad_bg-zebra2--mix-60: hsl(1, 47%, 40%);
  --m-bad_clearer--mix-80: hsl(0, 60%, 60%);
  --m-bad_dark--mix-80: hsl(0, 55%, 44%);
  --m-bg--alpha-40: hsla(0, 0%, 0%, 0.4);
  --m-bg--fade-20: hsla(0, 0%, 0%, 0.48);
  --m-bg--fade-30: hsla(0, 0%, 0%, 0.42);
  --m-bg--fade-40: hsla(0, 0%, 0%, 0.36);
  --m-bg--fade-50: hsla(0, 0%, 0%, 0.3);
  --m-bg-page--fade-40: hsla(37, 10%, 8%, 0.6);
  --m-bg-page_bg--mix-50: hsla(45, 10%, 4%, 0.8);
  --m-bg-zebra2--fade-1: hsla(37, 5%, 24%, 0.99);
  --m-bg_bad--mix-80: hsla(0, 60%, 10%, 0.68);
  --m-bg_high--lighten-11: hsla(0, 0%, 11%, 0.6);
  --m-blunder_bg--mix-25: hsla(0, 46%, 15%, 0.7);
  --m-blunder_bg--mix-30: hsla(0, 46%, 18%, 0.72);
  --m-blunder_bg--mix-60: hsla(0, 46%, 36%, 0.84);
  --m-blunder_font--mix-70: hsl(0, 57%, 66%);
  --m-body-gradient_bg-page--mix-50: hsl(39, 11%, 12%);
  --m-brag--alpha-70: hsla(37, 74%, 43%, 0.7);
  --m-brag--fade-50: hsla(37, 74%, 43%, 0.5);
  --m-brag_bg--mix-15: hsla(37, 74%, 6%, 0.66);
  --m-brag_bg--mix-20: hsla(37, 74%, 9%, 0.68);
  --m-brag_bg--mix-40: hsla(37, 74%, 17%, 0.76);
  --m-brag_bg--mix-60: hsla(37, 74%, 26%, 0.84);
  --m-brag_bg--mix-70: hsla(37, 74%, 30%, 0.88);
  --m-brag_bg--mix-80: hsla(37, 74%, 35%, 0.92);
  --m-brag_bg-page--mix-20: hsl(38, 47%, 15%);
  --m-brag_bg-page--mix-60: hsl(37, 67%, 29%);
  --m-brag_dark--mix-85: hsl(37, 68%, 40%);
  --m-brag_font--mix-60: hsl(37, 45%, 58%);
  --m-brag_white--mix-25: hsl(37, 56%, 86%);
  --m-brag_white--mix-80: hsl(37, 56%, 55%);
  --m-brilliant_bg--mix-25: hsla(129, 71%, 11%, 0.7);
  --m-brilliant_bg--mix-30: hsla(129, 71%, 13%, 0.72);
  --m-brilliant_bg--mix-60: hsla(129, 71%, 27%, 0.84);
  --m-clas--alpha-30: hsla(255, 83%, 65%, 0.3);
  --m-clas_bg--mix-10: hsla(255, 45%, 6%, 0.64);
  --m-clas_bg--mix-30: hsla(255, 45%, 19%, 0.72);
  --m-clas_bg--mix-7: hsla(255, 45%, 5%, 0.63);
  --m-clas_bg--mix-80: hsla(255, 49%, 52%, 0.92);
  --m-clas_bg-zebra--mix-20: hsla(255, 83%, 93%, 0.24);
  --m-clas_white--mix-20: hsl(255, 83%, 93%);
  --m-clas_white--mix-28: hsl(255, 83%, 90%);
  --m-clearer_bg-page--mix-70: hsl(45, 1%, 72%);
  --m-clearer_bg-page--mix-80: hsl(45, 1%, 82%);
  --m-clearer_body-gradient--mix-65: hsl(36, 2%, 71%);
  --m-clearer_body-gradient--mix-75: hsl(36, 2%, 79%);
  --m-dark--fade-40: hsla(0, 0%, 20%, 0.6);
  --m-dimmer_clearer--mix-63: hsl(0, 0%, 37%);
  --m-dimmer_font--mix-60: hsl(0, 0%, 32%);
  --m-dimmer_font--mix-90: hsl(0, 0%, 8%);
  --m-font--fade-50: hsla(0, 0%, 80%, 0.5);
  --m-font--fade-70: hsla(0, 0%, 80%, 0.3);
  --m-font--fade-83: hsla(0, 0%, 80%, 0.17);
  --m-font_bg--mix-20: hsla(0, 0%, 16%, 0.68);
  --m-font_bg--mix-30: hsla(0, 0%, 24%, 0.72);
  --m-font_clas--mix-40: hsl(255, 60%, 71%);
  --m-font_dimmer--mix-50: hsl(0, 0%, 40%);
  --m-font_dimmer--mix-85: hsl(0, 0%, 68%);
  --m-font_white--mix-20: hsl(0, 0%, 96%);
  --m-good_bg--mix-25: hsla(88, 62%, 9%, 0.7);
  --m-good_bg--mix-30: hsla(88, 62%, 11%, 0.72);
  --m-good_bg--mix-60: hsla(88, 62%, 22%, 0.84);
  --m-inaccuracy_bg--mix-25: hsla(202, 48%, 16%, 0.7);
  --m-inaccuracy_bg--mix-30: hsla(202, 48%, 19%, 0.72);
  --m-inaccuracy_bg--mix-60: hsla(202, 48%, 37%, 0.84);
  --m-inaccuracy_font--mix-70: hsl(202, 64%, 68%);
  --m-interesting_bg--mix-25: hsla(307, 34%, 18%, 0.7);
  --m-interesting_bg--mix-30: hsla(307, 34%, 21%, 0.72);
  --m-interesting_bg--mix-60: hsla(307, 34%, 42%, 0.84);
  --m-mistake_bg--mix-25: hsla(41, 100%, 11%, 0.7);
  --m-mistake_bg--mix-30: hsla(41, 100%, 14%, 0.72);
  --m-mistake_bg--mix-60: hsla(41, 100%, 27%, 0.84);
  --m-mistake_font--mix-70: hsl(41, 71%, 56%);
  --m-paper--fade-40: hsla(60, 56%, 91%, 0.6);
  --m-paper_dimmer--mix-50: hsl(60, 6%, 45%);
  --m-paper_dimmer--mix-75: hsl(60, 12%, 68%);
  --m-primary--alpha-30: hsla(209, 79%, 56%, 0.3);
  --m-primary--alpha-70: hsla(209, 79%, 56%, 0.7);
  --m-primary--fade-50: hsla(209, 79%, 56%, 0.5);
  --m-primary--fade-70: hsla(209, 79%, 56%, 0.3);
  --m-primary--fade-80: hsla(209, 79%, 56%, 0.2);
  --m-primary--lighten-10: hsl(209, 79%, 66%);
  --m-primary--lighten-11: hsl(209, 79%, 67%);
  --m-primary--lighten-7: hsl(209, 79%, 63%);
  --m-primary_bg--mix-10: hsla(209, 62%, 6%, 0.64);
  --m-primary_bg--mix-12: hsla(209, 62%, 7%, 0.65);
  --m-primary_bg--mix-15: hsla(209, 62%, 8%, 0.66);
  --m-primary_bg--mix-17: hsla(209, 62%, 10%, 0.67);
  --m-primary_bg--mix-18: hsla(209, 62%, 10%, 0.67);
  --m-primary_bg--mix-20: hsla(209, 62%, 11%, 0.68);
  --m-primary_bg--mix-25: hsla(209, 62%, 14%, 0.7);
  --m-primary_bg--mix-30: hsla(209, 62%, 17%, 0.72);
  --m-primary_bg--mix-35: hsla(209, 62%, 20%, 0.74);
  --m-primary_bg--mix-40: hsla(209, 62%, 22%, 0.76);
  --m-primary_bg--mix-50: hsla(209, 62%, 28%, 0.8);
  --m-primary_bg--mix-60: hsla(209, 62%, 34%, 0.84);
  --m-primary_bg--mix-70: hsla(209, 62%, 39%, 0.88);
  --m-primary_bg--mix-75: hsla(209, 62%, 42%, 0.9);
  --m-primary_bg--mix-80: hsla(209, 62%, 45%, 0.92);
  --m-primary_bg-page--mix-15: hsl(206, 30%, 15%);
  --m-primary_bg-page--mix-20: hsl(207, 36%, 17%);
  --m-primary_bg-page--mix-60: hsl(209, 56%, 37%);
  --m-primary_bg-page__mix-20--fade-70: hsla(209, 79%, 56%, 0.3);
  --m-primary_bg-zebra--mix-10: hsla(209, 79%, 96%, 0.15);
  --m-primary_bg-zebra2--mix-20: hsl(207, 20%, 30%);
  --m-primary_black--mix-70: hsl(209, 62%, 39%);
  --m-primary_black--mix-80: hsl(209, 62%, 45%);
  --m-primary_dark--mix-80: hsl(209, 57%, 49%);
  --m-primary_font--mix-35: hsl(209, 43%, 72%);
  --m-primary_font--mix-50: hsl(209, 54%, 68%);
  --m-primary_font--mix-90: hsl(209, 75%, 58%);
  --m-primary_white--mix-30: hsl(209, 79%, 87%);
  --m-primary_white--mix-40: hsl(209, 79%, 82%);
  --m-primary_white--mix-60: hsl(209, 79%, 74%);
  --m-primary_white--mix-80: hsl(209, 79%, 65%);
  --m-secondary--fade-30: hsla(88, 62%, 37%, 0.7);
  --m-secondary--fade-40: hsla(88, 62%, 37%, 0.6);
  --m-secondary--fade-50: hsla(88, 62%, 37%, 0.5);
  --m-secondary--fade-60: hsla(88, 62%, 37%, 0.4);
  --m-secondary--fade-80: hsla(88, 62%, 37%, 0.2);
  --m-secondary--lighten-11: hsl(88, 62%, 48%);
  --m-secondary--lighten-4: hsl(88, 62%, 41%);
  --m-secondary_bg--mix-10: hsla(88, 62%, 4%, 0.64);
  --m-secondary_bg--mix-12: hsla(88, 62%, 4%, 0.65);
  --m-secondary_bg--mix-15: hsla(88, 62%, 6%, 0.66);
  --m-secondary_bg--mix-20: hsla(88, 62%, 7%, 0.68);
  --m-secondary_bg--mix-25: hsla(88, 62%, 9%, 0.7);
  --m-secondary_bg--mix-30: hsla(88, 62%, 11%, 0.72);
  --m-secondary_bg--mix-35: hsla(88, 62%, 13%, 0.74);
  --m-secondary_bg--mix-40: hsla(88, 62%, 15%, 0.76);
  --m-secondary_bg--mix-50: hsla(88, 62%, 19%, 0.8);
  --m-secondary_bg--mix-70: hsla(88, 62%, 26%, 0.88);
  --m-secondary_bg--mix-75: hsla(88, 62%, 28%, 0.9);
  --m-secondary_bg--mix-80: hsla(88, 62%, 30%, 0.92);
  --m-secondary_bg-page--mix-50: hsl(87, 53%, 22%);
  --m-secondary_bg-popup--mix-10: hsla(88, 62%, 4%, 0.64);
  --m-secondary_bg-popup--mix-27: hsla(88, 62%, 10%, 0.71);
  --m-secondary_bg-popup--mix-36: hsla(88, 62%, 13%, 0.74);
  --m-secondary_bg-zebra--mix-12: hsla(88, 36%, 92%, 0.16);
  --m-secondary_bg-zebra--mix-20: hsla(88, 36%, 87%, 0.24);
  --m-secondary_dark--mix-50: hsl(88, 40%, 29%);
  --m-secondary_dark--mix-85: hsl(88, 57%, 35%);
  --m-secondary_dimmer--mix-82: hsl(88, 62%, 30%);
  --m-secondary_white--mix-80: hsl(88, 37%, 50%);
  --m-shade--fade-40: hsla(0, 0%, 30%, 0.6);
  --m-yellow_bg--mix-30: hsla(60, 100%, 15%, 0.72);
}


@import '../theme/font-face';
@import '../theme/default';

:root {
  @include shared-color-defs;
  @include default-mix;
}

@import '../theme/light';
@import '../theme/transp';
